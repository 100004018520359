<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.document") }}
              </div>
              <div class="block-sarche">
                <div class="header__search mobile__margintop d-flex">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="8" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              class="mobile__margintop"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'candidates.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          :class="mode ? 'table__myday' : 'table__mynight'"
          class="table-my-code table-bordered"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>
              <th v-if="columns.documentType.show">
                {{ columns.documentType.title }}
              </th>
              <th v-if="columns.branch.show">
                {{ columns.branch.title }}
              </th>
              <th v-if="columns.manager.show">
                {{ columns.manager.title }}
              </th>
              <th v-if="columns.file.show">
                {{ columns.file.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.name.show">
                <crm-input
                  v-model="filterForm.name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :size="'mini'"
                  :placeholder="columns.name.title"
                ></crm-input>
              </th>
              <th v-if="columns.id.show">
                <select-document-type
                  :id="filterForm.document_type_id"
                  :size="'mini'"
                  v-model="filterForm.document_type_id"
                ></select-document-type>
              </th>

              <th v-if="columns.branch.show">
                <select-branch
                  v-model="filterForm.branch_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :size="'mini'"
                ></select-branch>
              </th>
              <th v-if="columns.manager.show">
                <select-staff
                  v-model="filterForm.manager_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :size="'mini'"
                  :id="filterForm.manager_id"
                ></select-staff>
              </th>
              <th v-if="columns.file.show"></th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  v-model="filterForm.created_at"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.created_at.title"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  v-model="filterForm.updated_at"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.updated_at.title"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="(candidate, index) in list"
              :key="'candidate' + index"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ candidate.id }}</td>
              <td v-if="columns.name.show">
                {{ candidate.name }}
              </td>
              <td v-if="columns.documentType.show">
                {{
                  candidate.document_type ? candidate.document_type.name : ""
                }}
              </td>
              <td v-if="columns.branch.show">
                {{ candidate.branch ? candidate.branch.name : "" }}
              </td>
              <td v-if="columns.manager.show">
                {{
                  candidate.manager
                    ? candidate.manager.name + " " + candidate.manager.last_name
                    : ""
                }}
              </td>
              <td v-if="columns.file.show">
                <a
                  v-if="candidate && candidate.file"
                  :href="baseUrl + candidate.file"
                  target="_blank"
                  >{{ columns.file.title }}</a
                >
              </td>
              <td v-if="columns.created_at.show">
                {{ candidate.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ candidate.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :model="candidate"
                  :actions="actions"
                  :permissionShow="'candidates.update'"
                  :permissionDestroy="'candidates.destroy'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          :wrapperClosable="false"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>
        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          :wrapperClosable="false"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
import list from "@/utils/mixins/list";
import { mapGetters, mapActions, mapState } from "vuex";
import Pagination from "@/components/pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";

export default {
  name: "CandidateController",
  mixins: [list],
  components: {
    CrmCreate,
    Pagination,
    CrmUpdate
  },

  data() {
    return {
      drawerCreateStaff: false,
      selectedCandidate: null,
      showInfoModal: false,
      child: {},
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      )
    };
  },
  computed: {
    ...mapGetters({
      list: "documents/list",
      columns: "documents/columns",
      pagination: "documents/pagination",
      statues: "documents/statues",
      filter: "documents/filter",
      sort: "documents/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "documents/index",
      setPagination: "documents/setPagination",
      updateSort: "documents/updateSort",
      updateFilter: "documents/updateFilter",
      updateColumn: "documents/updateColumn",
      downloadFile: "documents/downloadFile",
      updatePagination: "documents/updatePagination",
      show: "documents/show",
      empty: "documents/empty",
      delete: "documents/destroy",
      refreshData: "documents/refreshData"
    }),

    addStaff(id) {
      this.selectedCandidate = id;
      this.drawerCreateStaff = true;
    },
    downloadFiles(id, name) {
      var data = { id: id };
      return this.downloadFile(data).then(response => {
        let blob = new Blob([response.data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        console.log(link);
      });
    },
    showInfoList(candidate_id) {
      this.showInfoModal = true;
      this.child.candidate_id = candidate_id;
    },
    closeModal(val) {
      this.showInfoModal = val;
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    }
  }
};
</script>
