<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p
            class="large--title m-0"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            {{ $t("message.document") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-2"
                  >
                    {{ $t("message.name") }}
                  </span>
                  <el-form-item prop="name">
                    <el-input
                      :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="columns.name.title"
                      v-model="form.name"
                      :size="'medium'"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-2"
                    >{{ $t("message.documentType") }}</span
                  >
                  <el-form-item prop="document_type_id">
                    <select-document-type
                      :id="form.document_type_id"
                      :size="'medium'"
                      v-model="form.document_type_id"
                    ></select-document-type>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-2"
                    >{{ $t("message.responsible") }}</span
                  >
                  <el-form-item prop="manager_id">
                    <select-staff
                      :id="form.manager_id"
                      :size="'medium'"
                      v-model="form.manager_id"
                    ></select-staff>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-2"
                  >
                    {{ $t("message.branch") }}
                  </span>
                  <el-form-item prop="branch_id">
                    <select-branch
                      :id="form.branch_id"
                      :value="form.branch_id"
                      :size="'medium'"
                      v-model="form.branch_id"
                    >
                    </select-branch>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->

              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-2"
                  >
                    {{ $t("message.file") }}
                  </span>
                  <el-upload
                    :on-preview="handleFilePreview"
                    :on-remove="handleFileRemove"
                    :before-remove="beforeFileRemove"
                    action="/"
                    :limit="1"
                    :size="'medium'"
                    :on-change="createFileList"
                    class="upload-demo"
                    :file-list="fileList"
                    list-type="file"
                    :auto-upload="false"
                    :class="mode ? 'input__day' : 'input__night'"
                  >
                    <el-button size="small" type="primary">
                      {{ $t("message.UploadFile") }}
                    </el-button>
                    <div slot="tip" class="el-upload__tip">
                      {{ $t("message.UploadFileText") }}
                    </div>
                  </el-upload>
                </div>
              </el-col>
              <!-- end col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  name: "CandidateController",
  components: {},
  props: {
    vacancy: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      fileList: [],
      imageList: [],
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  watch: {
    vacancy: {
      handler: function() {
        //   setTimeout(()=>{
        //     this.form.vacancy_ids.push(this.vacancy.id);
        //   })
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      rules: "documents/rules",
      model: "documents/model",
      columns: "documents/columns",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      save: "documents/store",
      empty: "documents/empty"
    }),
    handleFilePreview(file) {
      console.log(file);
    },
    handleFileRemove(file, fileList) {
      console.log(file, fileList);
    },
    beforeFileRemove(file, fileList) {
      //return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
    },
    handleImageRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    createFileList(file) {
      this.form.files = file.raw;
    },
    createImageList(file) {
      this.form.image = file.raw;
    },
    submit(close = true) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let formData = new FormData();
          for (var propName in this.form) {
            if (
              this.form[propName] === null ||
              this.form[propName] === undefined ||
              this.form[propName] == ""
            ) {
              delete this.form[propName];
            }
          }
          for (const key in this.form) {
            formData.append(key, this.form[key]);
          }
          this.save(formData)
            .then(res => {
              this.parent().listChanged();
              this.$alert(res);
              if (res.status == 201) {
                this.close();
                this.empty();
                this.fileList = [];
                this.imageList = [];
              }
            })
            .catch(err => {
              this.$alert(err);
            });
        }
      });
    },
    afterOpen() {
      if (this.vacancy) {
        this.form.vacancy_ids.push(this.vacancy.id);
      }
    },
    afterLeave() {
      this.empty();
      this.fileList = [];
      this.imageList = [];
    }
  }
};
</script>
<style lang="scss">
.my-upload-2 {
  .upload-demo {
    position: relative;
  }
  .el-upload-list {
    position: relative;
    z-index: 2;
  }
  .el-upload.el-upload--picture-card {
    position: absolute;
    left: 0px !important;
    z-index: 1;
  }
}
.upload__mynight {
  .el-upload.el-upload--picture-card {
    background-color: transparent;
  }
}
</style>
